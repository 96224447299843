<template>
    <div>
        <div class="infoBox" style="padding-top: 5px; padding-bottom: 20px">
            <div v-loading="Loading">
                <el-input v-model="commentValue" autocomplete="off" placeholder="请输入你要创建的入驻分类"
                    style="width: 20%;"></el-input>
                <el-button class="ml20" type="primary" size="small" @click="addComment('add')">+ 添加</el-button>
                <div v-if="commentList.length > 0">
                    <div v-for="item in commentList" :key="item.id" style="display: flex; align-items: center;">
                        <div class="commentStyle">
                            <div v-if="item.id != edit_id">{{ item.text }}</div>
                            <el-input v-else v-model="editCommentValue" autocomplete="off"></el-input>
                        </div>
                        <div class="flexA">
                            <el-button v-if="item.id == edit_id" type="primary" size="small" class="mr10" @click="addComment('edit')">完成</el-button>
                            <el-button icon="el-icon-edit" circle class="mr10" type="primary"
                                @click="editInfo(item)"></el-button>
                            <el-button icon="el-icon-delete" circle type="danger"
                                @click="deleteInfo('comment', item.id)"></el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    AddCommentInfoApi,
    DelCommentInfoApi,
    EditCommentInfoApi,
    GetCommentListApi,
} from "@/common/api";
import { Loading } from "element-ui";
export default {
    data() {
        return {
            loadShow: false,
            Loading: false,
            commentValue: '',    //评论语
            editCommentValue: '',    //评论语
            commentList: [],   //评论语列表
            pageNum: 1, // 当前页数
            total: 0, // 总页数
            edit_id: '',  //编辑评论语的id
        };
    },
    async created() {
        this.getTableData(1)
    },
    methods: {
        refresh(){
            this.getTableData(1)
        },
        // 获取数据信息
        async getTableData(page) {
            this.Loading = true
            if (page) this.pageNum = page;
            let res = await GetCommentListApi({
                    page: 1
                })
                this.Loading = false
                if (!res) return
                this.commentList = res.data
        },
        // 添加评论语
        async addComment(type) {
            let res
            if(type == 'add'){
                res = await AddCommentInfoApi({
                    text: this.commentValue
                })
            }else{
                this.Loading = true
                res = await EditCommentInfoApi({
                    id: this.edit_id,
                    text: this.editCommentValue
                })
            }
            if (!res) return
            this.edit_id = ''
            this.commentValue = ''
            this.editCommentValue = ''
            this.getTableData(1)
        },
        // 编辑
        editInfo(row) {
            this.edit_id = row.id
            this.editCommentValue = row.text
        },
        deleteInfo(item){
            this.$confirm('是否确定要【删除】?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then( async() => {
                let res = await DelCommentInfoApi({
                    id: item
                })
                if(!res) return
                this.getTableData(1)
            })
        },
    }
};
</script>

<style lang="less" scoped>
.commentStyle {
    height: 60px;
    box-shadow: 0 0 5px #ccc;
    border-radius: 5px;
    margin: 20px 50px 10px 20px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 86%;
}
</style>